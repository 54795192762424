import banana from '../../imagens/banana.svg'
import maracuja from '../../imagens/maracuja.svg'
import cacau from '../../imagens/cacau.svg'


export const sabores = [
   
    {nome: 'Licor Fino de Banana', id: 7, src: banana},
    {nome: 'Licor Fino de coco', id: 7, src: cacau},
    {nome: 'Licor Fino de maracuja ', id: 7, src: maracuja},
    
]  
    